import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { PopupButton } from '@typeform/embed-react'
import { Link } from 'gatsby'
import { useScrollDirection } from 'react-use-scroll-direction'
import { sanityImageUrl, urlWithSearchParamsHandler, clickTrack } from '../../../utils/format'
import styles from './style.module.sass'

const SignUpButton = ({ url, label, setMobileOpen }) => {
  const checkUrl = () => {
    if (url === '/#protocols' || url === '/#reviews') {
      document.body.classList.remove(styles.mobileOpened)
      setMobileOpen(false)
    }
  }

  if (url.includes('https://quiz/')) {
    return (
      <PopupButton
        id={url.replace('https://quiz/', '')}
        as="a"
        onReady={() => clickTrack(label)}
        transitiveSearchParams={true}
        className={styles.btnsSignUp}
        buttonProps={{ id: 'btn-action' }}
      >
        {label}
      </PopupButton>
    )
  }

  if (url.includes('https://')) {
    return (
      <a href={url} id="btn-action" onClick={urlWithSearchParamsHandler} className={styles.btnsSignUp}>
        {label}
      </a>
    )
  }

  return (
    <Link to={url} id="btn-action" onClick={checkUrl} className={styles.btnsSignUp}>
      {label}
    </Link>
  )
}

const SmartLink = ({ item }) => (
  <>
    {item.newTab ? (
      <a title={item.metaTitle} target="_blank" href={item.href}>
        {item.title}
      </a>
    ) : (
      <Link title={item.metaTitle} to={item.href}>
        {item.title}
      </Link>
    )}
  </>
)

const ProtocolsList = ({ protocols }) => (
  <div className={styles.protocols}>
    {protocols &&
      protocols.items &&
      protocols.items.filter(item => !item.onlyForCatalog).map(item => (
        <Link
          key={item._key}
          title={item.linkTitleNav}
          aria-label={item.learnMoreButtonAreaLabel}
          className={styles.protocol}
          to={item.learnMoreButtonUrl}
        >
          <img
            className={styles.protocolImage}
            src={sanityImageUrl(item.imageVertical)}
            alt={item.imageVertical?.caption}
          />
          <p className={styles.protocolName} dangerouslySetInnerHTML={{ __html: item.nameNav }} />
          <p className={styles.protocolMotto}>{item.mottoNav}</p>
        </Link>
      ))}
  </div>
)

const ProtocolsListMobile = ({ protocols }) => (
  <>
    {protocols.items &&
      protocols.items.filter(item => !item.onlyForCatalog).map(item => (
        <Link
          key={item._key}
          title={item.linkTitleNav}
          aria-label={item.learnMoreButtonAreaLabel}
          to={item.learnMoreButtonUrl}
        >
          <span dangerouslySetInnerHTML={{ __html: item.nameNav }}></span>
        </Link>
      ))}
  </>
)

const DropdownColumns = ({ item, ytDetected }) => (
  <div className={styles.columns}>
    <div className={styles.left}>
      <p className={styles.columnsTitle}>{item.subLinksLeftTitle}</p>
      <ul className={styles.columnsLinks}>
        {item.subLinksLeft.map(subItem => (
          <li key={subItem._key} className={styles.columnsLink}>
            <SmartLink item={subItem} />
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.right}>
      <p className={styles.columnsTitle}>{item.subLinksRightTitle}</p>
      <ul className={styles.columnsLinks}>
        {item.subLinksRight.map(subItem => (
          <>
            {!(ytDetected && subItem.title === 'Sexual Enhancement') && (
              <li key={subItem._key} className={styles.columnsLink}>
                <SmartLink item={subItem} />
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  </div>
)

const DropdownColumnsMobile = ({ item }) => (
  <>
    <p>{item.subLinksLeftTitle}</p>
    {item.subLinksLeft.map(subItem => (
      <SmartLink item={subItem} />
    ))}
    <p>{item.subLinksRightTitle}</p>
    {item.subLinksRight.map(subItem => (
      <SmartLink item={subItem} />
    ))}
  </>
)

export default ({ navbar, protocols, signUpUrl, signUpText, signInHide, topBar, navStatic }) => {
  const navRef = useRef()
  const direction = useScrollDirection()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [scrollBefore, setScrollBefore] = useState(0)
  const [mobileOpenSections, setMobileOpenSections] = useState([])
  const [ytDetected, setYtDetected] = useState(false)

  const scrollHandler = () => {
    const scroll = document.documentElement.scrollTop

    if (scroll > 0) {
      navRef.current.classList.add(styles.navFixed)
    } else {
      navRef.current.classList.remove(styles.navFixed)
      navRef.current.classList.remove(styles.navHidden)
      navRef.current.classList.remove(styles.navVisible)
    }

    if (direction.isScrollingDown) {
      navRef.current.classList.add(styles.navHidden)
      navRef.current.classList.remove(styles.navVisible)
    }

    if (direction.isScrollingUp) {
      navRef.current.classList.remove(styles.navHidden)
      navRef.current.classList.add(styles.navVisible)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [direction])

  const mobileMenuHandler = () => {
    if (mobileOpen) {
      document.body.classList.remove(styles.mobileOpened)
      document.documentElement.scrollTop = scrollBefore
    } else {
      setScrollBefore(document.documentElement.scrollTop)
      document.body.classList.add(styles.mobileOpened)
      document.getElementsByClassName(styles.mobile)[0].scrollTop = 0
    }

    setMobileOpen(!mobileOpen)
  }

  const toggleMobileMenu = key => {
    if (mobileOpenSections.includes(key)) {
      setMobileOpenSections(mobileOpenSections.filter(i => i !== key))
    } else {
      setMobileOpenSections([...mobileOpenSections, key])
    }
  }

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  const ytDetect = () => {
    const href = document.location.href
    if (~href.indexOf('-yt')) {
      setYtDetected(true)
    }
  }

  useEffect(() => {
    appHeight()
    ytDetect()
    window.addEventListener('resize', appHeight)
    document.body.classList.remove(styles.mobileOpened)

    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  const signUpButtonUrl = signUpUrl ? signUpUrl : navbar.signUpUrl
  const signUpButtonLabel = signUpText ? signUpText : navbar.signUpText

  return (
    <div className={cx(styles.nav, {
      [styles.navStatic]: navStatic
    })} ref={navRef}>
      {topBar}
      <div className={styles.wrap}>
        <Link to="/" className={styles.logo} role="navigation" aria-label="Home page" />

        <ul className={styles.links}>
          {navbar.links &&
            navbar.links.map(item => (
              <li
                key={item._key}
                className={cx(styles.link, {
                  [styles.linkMultiple]: item.subLinksLeft || item.subLinksRight || item.protocolsList,
                })}
              >
                {item.subLinksLeft || item.subLinksRight || item.protocolsList ? (
                  <>
                    {item.title}
                    <div className={styles.dropdown}>
                      <div className={styles.dropdownWrap}>
                        {item.protocolsList ? (
                          <ProtocolsList protocols={protocols} />
                        ) : (
                          <DropdownColumns item={item} ytDetected={ytDetected} />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <SmartLink item={item} />
                )}
              </li>
            ))}
        </ul>

        <div className={styles.btns}>
          <SignUpButton url={signUpButtonUrl} label={signUpButtonLabel} setMobileOpen={setMobileOpen} />

          {!signInHide && (
            <a href={navbar.signInUrl} onClick={urlWithSearchParamsHandler} className={styles.btnsSignIn}>
              {navbar.signInText}
            </a>
          )}

          <div
            className={cx(styles.mobileBtnNav, {
              [styles.mobileBtnNavClose]: mobileOpen,
            })}
            onClick={mobileMenuHandler}
          />
        </div>
      </div>

      <div className={styles.mobile}>
        <div className={styles.mobileWrap}>
          <ul className={styles.mobileLinks}>
            {navbar.links &&
              navbar.links.map(item => (
                <li
                  key={item._key}
                  className={cx(styles.mobileLink, {
                    [styles.mobileLinkMultiple]: item.subLinksLeft || item.subLinksRight || item.protocolsList,
                    [styles.mobileLinkMultipleOpen]: mobileOpenSections.includes(item._key),
                  })}
                >
                  {item.subLinksLeft || item.subLinksRight || item.protocolsList ? (
                    <>
                      <span onClick={() => toggleMobileMenu(item._key)}>{item.title}</span>
                      <div className={styles.mobileDropdown}>
                        {item.protocolsList ? (
                          <ProtocolsListMobile protocols={protocols} />
                        ) : (
                          <DropdownColumnsMobile item={item} />
                        )}
                      </div>
                    </>
                  ) : (
                    <SmartLink item={item} />
                  )}
                </li>
              ))}
          </ul>

          {!signInHide && (
            <a href={navbar.signInUrl} onClick={urlWithSearchParamsHandler} className={styles.mobileSignIn}>
              {navbar.signInText}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
