import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import regexifyString from 'regexify-string'
import { getImageAsset, getFileAsset } from '@sanity/asset-utils'
import { Experiment } from '@amplitude/experiment-js-client'
import sanityClient from '@sanity/client'

const studioClient = sanityClient({
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  token: process.env.GATSBY_SANITY_API_KEY,
})

const isProd = process.env.NODE_ENV === 'production'

// How to use it in components
// useEffect(() => {
//   getAmplitudeExperiment('test-flag-for-website-integration', (variant) => console.log(variant))
// }, [])
export const getAmplitudeExperiment = async(id, callback) => {
  analytics.ready(async() => {
    const experiment = Experiment.initialize('client-SSdNm4FiU6mcPLMKkmWf9weHJEvCLMKi')
    await experiment.fetch({ device_id: analytics.user().anonymousId() })
    callback(experiment.variant(id))   
  })
}

export const prepareParagraph = text => {
  if (text) {
    return text.split('\n').map(p => {
      if (p) {
        const pWithLinksReplaced = regexifyString({
          pattern: /\[\[.*?\]\]\]|\[\[.*?\]\]|\[R\]/gim,
          decorator: (match, index) => {
            let target = '_self'

            if (match === '[r]') {
              return '[r]'
            }

            if (match === '[R]') {
              return <a href="#reference_[AUTOREF_COUNT]" target={target} className="autoref" />
            }

            const rule = match.replace(/^\[\[|\]\]$/g, '')
            const href = rule.substr(0, rule.indexOf(','))
            const link = rule.substr(rule.indexOf(',') + 1)

            if (~href.indexOf('http')) {
              target = '_blank'
            }

            if (~href.indexOf('mailto') || ~href.indexOf('modal')) {
              return (
                <a href={href} target={target}>
                  {link || href}
                </a>
              )
            }

            return (
              <Link to={href} target={target}>
                {link || href}
              </Link>
            )
          },
          input: p,
        })

        return (
          <>
            {pWithLinksReplaced.map(i => (typeof i === 'string' ? ReactHtmlParser(i) : i))}
            <br />
          </>
        )
      }

      return (
        <>
          <br />
        </>
      )
    })
  }

  return ''
}

export const timeSince = date => {
  const seconds = Math.floor((new Date() - date) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return `${Math.floor(interval)} Years Ago`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return `${Math.floor(interval)} Months Ago`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return `${Math.floor(interval)} Days Ago`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `${Math.floor(interval)} Hours Ago`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `${Math.floor(interval)} Minutes Ago`
  }

  return `${Math.floor(seconds)} Seconds Ago`
}

export const imageAutoFormat = (imageUrl, quality = 85, maxWidth = false) => {
  if (!imageUrl) {
    return imageUrl
  }

  let resultUrl = `${imageUrl}?auto=format&q=${quality}`

  if (maxWidth) {
    resultUrl += `&w=${maxWidth}&fit=max`
  }

  return resultUrl
}

export const srcSetProps = (
  imageUrl,
  sizes = [
    [800, 800],
    [480, 480],
  ],
  quality,
  maxWidth,
) => {
  if (!imageUrl) {
    return
  }

  const splittedImageUrl = imageUrl.split('.')
  const ext = splittedImageUrl[splittedImageUrl.length - 1]

  if (ext === 'svg') {
    return { src: imageUrl }
  }

  return {
    src: imageAutoFormat(imageUrl, quality, maxWidth),
    srcset: `${sizes
      .map(([s, w]) => `${imageAutoFormat(imageUrl, quality)}&w=${s}&fit=max ${w}w`)
      .join(',\n')},\n${imageAutoFormat(imageUrl, quality, maxWidth)}`,
  }
}

export const urlWithSearchParamsHandler = e => {
  e.preventDefault()

  clickTrack(e.target.text)

  const params =
    typeof window !== 'undefined' && window.localStorage ? window.localStorage.getItem('searchParams') || '' : ''
  const href = e.target.getAttribute('href')

  if (href.includes('?')) {
    location = href + (params ? params.replace('?', '&') : params)
  } else {
    location = href + params
  }
}

export const titleToId = title =>
  title
    .toLowerCase()
    .replace(/[ /]/g, '-')
    .replace(/[,.:&]/g, '')
    .replace(/--/g, '-')

export const buildLink = (title, children = []) => {
  if (title.name) {
    return title
  }

  const titleId = titleToId(title)
  return { name: title, link: `#${titleId}`, id: titleId, children: children.map(i => buildLink(i)) }
}

export function sanityImageUrl(source) {
  if (!source?.asset) {
    return
  }

  return getImageAsset(source.asset, studioClient.config()).url
}

export function sanityFileUrl(source) {
  if (!source?.asset) {
    return
  }

  return getFileAsset(source.asset, studioClient.config()).url
}

export function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export function clickTrack(button) {
  if (isProd) {
    analytics.track('click', {
      button: button
    })
  }
}

export function formAccessHandler(e, loading, setLoading, setSubmit) {
  e.preventDefault()
  const { email } = e.target
  if (!email.value || loading || !validateEmail(email.value)) { return }
  setLoading(true)
  fetch('https://app.maximustribe.com/community/access', {
    method: 'POST',
    body: JSON.stringify({ email: email.value, href: window.location.href }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    },
  })
  .then(() => {
    setTimeout(() => {
      setSubmit(true)
      setLoading(false)
    }, 600)
  })
  .catch(error => console.warn(error))  
}
